<template>
	<div class="list-tool-bar">
    <el-button @click="emit('onCreate')" type="primary" v-if="$userRole(['SalesMan', 'Enterprise'])">{{ t('table.create') }}</el-button>
    <el-button type="primary" plain @click="emit('onExport')">{{ t('table.export') }}</el-button>
		<div class="search">
			<div class="search_item">
				<el-select
          v-model="searchData.filter_product_id"
          :placeholder="t('product.product_name')"
          @change="doSearch"
          clearable
          filterable>
					<el-option :label="item.label" :value="item.value" v-for="(item, index) in productList"/>
				</el-select>
			</div>
			<div class="search_item">
				<el-select
          v-model="searchData.filter_status"
          :placeholder="t('order.approval_status')"
          @change="doSearch"
          filterable
          clearable>
					<el-option :label="t('order.status_0')" value="0" />
					<el-option :label="t('order.status_1')" value="1" />
					<el-option :label="t('order.status_2')" value="2" />
					<el-option :label="t('order.status_8')" value="8" />
					<el-option :label="t('order.status_9')" value="9" />
				</el-select>
			</div>
			<div class="search_item">
				<el-select
          v-model="searchData.filter_is_available"
          :placeholder="t('order.order_status')"
          @change="doSearch"
          filterable
          clearable>
					<el-option :label="t('order.available')" :value="true" />
					<el-option :label="t('order.unavailable')" :value="false" />
				</el-select>
			</div>
			<div class="search_item">
				<el-select
          v-model="searchData.filter_category_id"
          :placeholder="t('order.order_type')"
          @change="doSearch"
          clearable
          filterable>
					<el-option :label="t('order.type_1001')" :value="1001" />
					<el-option :label="t('order.type_1002')" :value="1002" />
					<el-option :label="t('order.type_1003')" :value="1003" />
				</el-select>
			</div>
			<div v-if="$userRole(['SuperAdmin', 'GeneralAdmin'])" class="search_item">
				<el-select
          v-model="searchData.filter_sales_user_id"
          :placeholder="t('dashboard.sales')"
          @change="doSearch"
          filterable
          clearable>
					<el-option v-for="(item, index) in salesList" :key="item.id" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div v-if="$userRole(['SuperAdmin', 'GeneralAdmin', 'SalesMan'])" class="search_item">
				<el-select
          v-model="searchData.filter_enterprise_id"
          :placeholder="t('dashboard.enterprise')"
          @change="doSearch"
          filterable
          clearable>
					<el-option v-for="(item, index) in enterpriseList" :key="item.id" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, onMounted, getCurrentInstance, inject } from 'vue'
import { getSalesProduct, getEnterpriseProductList } from '@/utils/common'
import { getEnterpriseList, getUserList, getUserProduct } from '@/utils/common'
import store from '@/store'

const emit = defineEmits(['getList', 'onExport', 'onCreate', 'getOrderAmount'])
const t = inject('t')
const enterpriseList = ref([])
const salesList = ref([])
const productList = ref([])
const { proxy } = getCurrentInstance()
const searchData = ref({
	page: 1
})

// 获取销售列表
const getSales = async () => {
	if (proxy.$userRole(['SuperAdmin', 'GeneralAdmin'])) {
		salesList.value = await getUserList({
			filter_role_alias: 'SalesMan',
			is_paginate: false
		})
	}
	// 企业列表
	if (proxy.$userRole(['SuperAdmin', 'GeneralAdmin', 'SalesMan'])) {
		enterpriseList.value = await getEnterpriseList()
	}
}

// 获取产品列表
const getProductList = async () => {
	if(proxy.$userRole(['Enterprise'])) {
		// 企业产品
		productList.value = await getEnterpriseProductList(store.state.users.enterprise_id)
	} else if(proxy.$userRole(['GeneralAdmin'])){
		// 运营查询所有产品
		productList.value = await getUserProduct()
	} else {
		// 销售产品
		productList.value = await getSalesProduct(store.state.users.id)
	}
}

onMounted(() => {
	getSales()
	getProductList()
})

// 搜索事件
const doSearch = () => {
	for (let key in searchData.value) {
		if (searchData.value[key] === '') {
			delete searchData.value[key]
		}
	}
	emit('getList', searchData.value)
	emit('getOrderAmount', searchData.value)
}
</script>
